import 'core-js/stable';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { iconsSet as icons } from './assets/icons/icons.js';
import ApiPlugin from './plugins/api';

function initPlugins(kauth) {
  Vue.use(kauth);

  Vue.use(new ApiPlugin('api', process.env.VUE_APP_DAQ_DATA_UPLOADER_URL, async (opt) => {
    const token = await kauth.getValidToken();
    const authHeaders = { Authorization: `Bearer ${token}` };
    opt.headers = { ...authHeaders, ...opt.headers };
    return opt;
  }));

  Vue.use(new ApiPlugin('daqApi', process.env.VUE_APP_DAQ_API_URL, async (opt) => {
    const token = await kauth.getValidToken();
    const authHeaders = { Authorization: `Bearer ${token}` };
    opt.headers = { ...authHeaders, ...opt.headers };
    return opt;
  }));
}

export default function init(kauth) {
  Vue.config.performance = true;
  initPlugins(kauth);

  new Vue({
    el: '#app',
    router,
    icons,
    render(h) {
      return h(App);
    }
  });
}
