import Vue from 'vue';
import Router from 'vue-router';

const MsiContainer = () => import('@/containers/MsiContainer');
const Home = () => import('@/views/Home');
const Page404 = () => import('@/views/Page404');

Vue.use(Router);

export default new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/',
      name: 'Root',
      component: MsiContainer,
      children: [
        {
          path: '',
          name: 'Home',
          component: Home
        }
      ]
    },
    {
      path: '*',
      name: 'Page 404',
      component: Page404
    }
  ];
}

