import qs from 'querystring';

class ApiPlugin {
  constructor(name, baseUrl, requestIntercept = opt => opt) {
    this.name = name;
    this.baseUrl = baseUrl;
    this.requestIntercept = requestIntercept;
  }

  async request(method, path, options = {}) {
    options = await this.requestIntercept(options);
    const queryStr = options.query ? `?${qs.stringify(options.query)}` : '';
    const url = `${this.baseUrl}${path}${queryStr}`;
    options.method = method;
    if (options.body && (method !== 'GET' || method !== 'HEAD')) {
      options.headers['Content-Type'] = 'application/json; charset=utf-8';
      options.body = JSON.stringify(options.body);
    }

    return fetch(url, options);
  }

  async requestHandler(method, path, options) {
    const response = await this.request(method, path, options);
    const body = await response.json();
    if (response.status >= 200 && response.status <= 299) return body;
    const apiError = new Error();
    apiError.name = 'ApiError';
    apiError.message = body.message || 'Error';
    apiError.status = response.status;
    apiError.originalResponse = body;
    throw apiError;
  }

  async get(path, options) {
    return this.requestHandler('GET', path, options);
  }

  async post(path, options) {
    return this.requestHandler('POST', path, options);
  }

  async put(path, options) {
    return this.requestHandler('PUT', path, options);
  }

  async delete(path, options) {
    return this.requestHandler('DELETE', path, options);
  }

  install(_Vue) {
    _Vue.prototype[`$${this.name}`] = this;
  }
}

export default ApiPlugin;