import {
  cilUser,
  cilAccountLogout
} from '@coreui/icons';

export const iconsSet = Object.assign(
  {
    cilUser,
    cilAccountLogout
  }
);
